
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import PInputSwitch from "primevue/inputswitch";
import PProgressBar from "primevue/progressbar";
import PMultiSelect from "primevue/multiselect";

import { EmployeeForm } from "@/store/employee/types";
import { getUserName, splitUserName, isEditFormByRouteName, formatCPF, removeCPFMask } from "@/libs/utils";

const VEmployeeForm = defineComponent({
  name: "VEmployeeForm",
  components: { CModal, CFormInputText, PInputSwitch, PProgressBar, PMultiSelect },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} usuário | obmed` });

    const employee = computed(() => store.state.employee.current);
    const groups = computed(() => store.state.user.groups);
    const establishments = computed(() => store.state.user.establishments);
    const show = reactive({ password: false });

    const form = reactive<EmployeeForm>({
      cd_estabelecimento: 0,
      cd_estabelecimentos: [],
      cd_grupos: [],
      ds_senha: "",
      ds_senha_rep: "",
      email: "",
      first_name: "",
      is_active: true,
      last_name: "",
      username: "",
    });
    const userName = ref("");
    const loading = reactive({ employee: false, submit: false });

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const [first_name, last_name] = splitUserName(userName.value);
      const response = isEditForm
        ? await store.dispatch("updateEmployee", {
            _id,
            form: { ...form, first_name, last_name, ds_senha: undefined, ds_senha_rep: undefined },
          })
        : await store.dispatch("createEmployee", { form: { ...form, first_name, last_name } });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getEmployees");
        router.replace({ name: "employee-list" });
      }
    }

    async function getEmployee() {
      loading.employee = true;
      await Promise.allSettled([
        store.dispatch("getEmployee", { _id }),
        store.dispatch("getUserGroups"),
        store.dispatch("getEmployeeEstablishments"),
      ]);
      loading.employee = false;
    }

    async function handleApplyForm() {
      await getEmployee();

      form.email = employee.value?.email || "";
      form.username = employee.value?.username || "";
      form.is_active = employee.value?.is_active ?? true;
      form.cd_grupos = employee.value?.grupos?.map((item) => item.id) || [];
      form.cd_estabelecimentos = employee.value?.estabelecimentos?.map((item) => item.id) || [];

      userName.value = getUserName(employee.value?.first_name, employee.value?.last_name);
    }

    if (isEditForm) handleApplyForm();
    else Promise.allSettled([store.dispatch("getUserGroups"), store.dispatch("getEmployeeEstablishments")]);

    return {
      ...{ form, userName, groups, show, establishments, loading, isEditForm },
      ...{ handleClose, onSubmit },
      ...{ formatCPF, removeCPFMask },
    };
  },
});

export default VEmployeeForm;
